.App {
  text-align: center;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(92, 124, 146, .5);
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1a2630;
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: unset !important;
  border: 1px solid #7d7d7d;
}

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

body {
  margin: 0;
  overflow-y: hidden;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Change input autofill style */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}
svg.MuiChip-deleteIcon {
  color: #f2f2f2 !important;
}
div.MuiChip-root {
  background-color: #57728A;
  height: 25px;
}

button.MuiButton-root.Mui-disabled {
  color: rgb(193 193 193 / 40%);
  background-color: rgb(0 0 0 / 10%);
}
